import "./styles.css";
import cutCosts from "../../img/featured/cut_costs.svg";
import effortless from "../../img/featured/effortless.svg";
import mitigate from "../../img/featured/mitigate.svg";
import totalControl from "../../img/featured/total_control.svg";
import { HeaderLink } from "components/Header/HeaderLink";

const Features = ({ showRedirectModal, redirectPopupTimestamp }) => {
  const LaunchExchangeButton = () => {
    return (
      <HeaderLink
        className="default-btn"
        to="/analytics"
        redirectPopupTimestamp={redirectPopupTimestamp}
        showRedirectModal={showRedirectModal}
      >
        Dashboard
      </HeaderLink>
    );
  };

  const featuresArr = [
    {
      image: mitigate,
      title: "Mitigate Liquidation Risks",
      description: "Our system uses a network of high-quality price feeds to minimize the chances of liquidation.",
    },
    {
      image: cutCosts,
      title: "Cut Costs",
      description: "Open and close positions with minimal spreads and no price impact, saving you money.",
    },
    {
      image: effortless,
      title: "Effortless Trading",
      description: "Open and close positions with minimal spreads and no price impact, saving you money.",
    },
    {
      image: totalControl,
      title: "Total Control",
      description: "Maintain full control of your funds at all times while trading.",
    },
  ];
  return (
    <div className="feature-section">
      <div className="default-container">
        <h2>Trading, Perfected for You</h2>
        <p>Experience the Best of DeFi with a Professional Touch</p>
        <div className="feature-section-card">
          {featuresArr.map((feature, index) => (
            <div key={index} className="card-item">
              <div>
                <div className="card-item-image">
                  <img src={feature.image} alt="" />
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
