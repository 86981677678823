import React from "react";

import cx from "classnames";

import "./Tab.css";
import SettingSlippageButton from "components/Common/SettingSlippageButton";

export default function Tab(props) {
  const { options, option, setOption, onChange, type = "block", className, optionLabels, icons, settingSlippage } = props;
  const onClick = (opt) => {
    if (setOption) {
      setOption(opt);
    }
    if (onChange) {
      onChange(opt);
    }
  };

  return (
    <div style={{display:"flex"}}>
      <div style={{flexGrow:'1'}}>
        <div className={cx("Tab", type, className)}>
          {options.map((opt) => {
            const label = optionLabels && optionLabels[opt] ? optionLabels[opt] : opt;
            return (
              <div className={cx("Tab-option", "muted", { active: opt === option })} onClick={() => onClick(opt)} key={opt}>
                {label}
                {icons && icons[opt] && <img className="Tab-option-icon" src={icons[opt]} alt={option} />}
              </div>
            );
          })}
        
        </div>
      </div>
      <div style={{flexGrow:'0', marginBottom:'1.05rem'}}>
      { (options[0] == "Long" || options[0] == "Swap") && (
          <SettingSlippageButton onClick={settingSlippage} />
        )}
      </div>
    </div>
  );
}
