import { useWeb3React } from "@web3-react/core";
import AddressDropdown from "../AddressDropdown/AddressDropdown";
import ConnectWalletButton from "../Common/ConnectWalletButton";
import React, { useCallback, useEffect } from "react";
import { HeaderLink } from "./HeaderLink";
import connectWalletImg from "img/ic_wallet_24.svg";

import "./Header.css";
import { isHomeSite, getAccountUrl } from "lib/legacy";
import cx from "classnames";
import { Trans } from "@lingui/macro";
import NetworkDropdown from "../NetworkDropdown/NetworkDropdown";
import LanguagePopupHome from "../NetworkDropdown/LanguagePopupHome";
import { DEFAULT_CHAIN_ID, getChainName } from "config/chains";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";

type Props = {
  openSettings: () => void;
  small?: boolean;
  setWalletModalVisible: (visible: boolean) => void;
  disconnectAccountAndCloseSettings: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
  isHomepage: boolean;
};

export function AppHeaderUser({
  openSettings,
  small,
  setWalletModalVisible,
  disconnectAccountAndCloseSettings,
  redirectPopupTimestamp,
  showRedirectModal,
  isHomepage
}: Props) {
  const { chainId } = useChainId();
  const { active, account } = useWeb3React();
  const showConnectionOptions = !isHomeSite();

  const networkOptions = [
    // {
    //   label: getChainName(ARBITRUM),
    //   value: ARBITRUM,
    //   icon: "ic_arbitrum_24.svg",
    //   color: "#264f79",
    // },
    // {
    //   label: getChainName(AVALANCHE),
    //   value: AVALANCHE,
    //   icon: "ic_avalanche_24.svg",
    //   color: "#E841424D",
    // },
    {
      label: getChainName(DEFAULT_CHAIN_ID),
      value: DEFAULT_CHAIN_ID,
      icon: "ic_aigisos_24.svg",
      color: "#E841424D",
    },
  ];

  useEffect(() => {
    if (active) {
      setWalletModalVisible(false);
    }
  }, [active, setWalletModalVisible]);

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === chainId) {
        return;
      }
      return switchNetwork(option.value, active);
    },
    [chainId, active]
  );

  const selectorLabel = getChainName(chainId);

  if (!active) {
    return (
      <div className="App-header-user">
        {isHomepage ? 
          <div className={cx("App-header-trade-link", { "homepage-header": isHomeSite() })}>
            <div className="header-link-root">
              <a target="_blank" href='https://finch.aidx.exchange/' rel="noopener noreferrer" className="header-link">
                <Trans>Finch AI Trading Helper</Trans>
              </a>
              <HeaderLink
                className="header-link"
                to="/earn"
                redirectPopupTimestamp={redirectPopupTimestamp}
                showRedirectModal={showRedirectModal}
              >
                <Trans>Earn</Trans>
              </HeaderLink>
            </div> 
          </div>
          :
          <>
            <div className={cx("App-header-trade-link", { "homepage-header": isHomeSite() })}>
              <a target="_blank" href='https://finch.aidx.exchange/' className="default-btn" rel="noopener noreferrer">
                Finch Ai Trading Helper
              </a>
            </div>
            <div className={cx("App-header-trade-link", { "homepage-header": isHomeSite() })}>
              <HeaderLink
                className="default-btn"
                to="/earn"
                redirectPopupTimestamp={redirectPopupTimestamp}
                showRedirectModal={showRedirectModal}
              >
                <Trans>Earn</Trans>
              </HeaderLink>
            </div>
          </>}

        {showConnectionOptions ? (
          <>
            {!isHomepage && (
              <ConnectWalletButton onClick={() => setWalletModalVisible(true)} imgSrc={connectWalletImg}>
                {small ? <Trans>Connect</Trans> : <Trans>Connect Wallet</Trans>}
              </ConnectWalletButton>
            )}
            <NetworkDropdown
              small={small}
              networkOptions={networkOptions}
              selectorLabel={selectorLabel}
              onNetworkSelect={onNetworkSelect}
              openSettings={openSettings}
              isHomepage={isHomepage}
            />
            {isHomepage && (
              <ConnectWalletButton onClick={() => setWalletModalVisible(true)} imgSrc={connectWalletImg} isHomepage>
                {small ? <Trans>Connect</Trans> : <Trans>Connect Wallet</Trans>}
              </ConnectWalletButton>
            )}
          </>
        ) : (
          <LanguagePopupHome />
        )}
      </div>
    );
  }

  const accountUrl = getAccountUrl(chainId, account);

  return (
    <div className="App-header-user">
      { isHomepage ?
        <div className={cx("App-header-trade-link", { "homepage-header": isHomeSite() })}>
          <div className="header-link-root">
            <a target="_blank" href='https://finch.aidx.exchange/' rel="noopener noreferrer" className="header-link">
              <Trans>Finch AI Trading Helper</Trans>
            </a>
            <HeaderLink
              className="header-link"
              to="/earn"
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
            >
              <Trans>Earn</Trans>
            </HeaderLink>
          </div>
        </div>
      :
        <>
          <div className={cx("App-header-trade-link", { "homepage-header": isHomeSite() })}>
            <a target="_blank" href='https://finch.aidx.exchange/' className="default-btn" rel="noopener noreferrer">
              Finch Ai Trading Helper
            </a>
          </div>
          <div className="App-header-trade-link">
            <HeaderLink
              className="default-btn"
              to="/earn"
              redirectPopupTimestamp={redirectPopupTimestamp}
              showRedirectModal={showRedirectModal}
            >
              <Trans>Earn</Trans>
            </HeaderLink>
          </div>
        </>
      }
      {showConnectionOptions ? (
        <>
          <div className="App-header-user-address">
            <AddressDropdown
              account={account}
              accountUrl={accountUrl}
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
            />
          </div>
          <NetworkDropdown
            small={small}
            networkOptions={networkOptions}
            selectorLabel={selectorLabel}
            onNetworkSelect={onNetworkSelect}
            openSettings={openSettings}
            isHomepage={isHomepage}
          />
        </>
      ) : (
        <LanguagePopupHome />
      )}
    </div>
  );
}
