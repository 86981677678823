import React from "react";

import { useEffect } from "react";
import DashboardV2 from "./DashboardV2";

export default function Dashboard(props) {  
  const { setIsHomepage } = props;
  useEffect(() => {setIsHomepage(false)}, [])
  return <DashboardV2 />;
}
