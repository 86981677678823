import { useState } from "react";
import "./styles.css";
import MobileFriendly from "../../img/features/mobile-friendly.png";
import FullyDecentralized from "../../img/features/fully-decentralized.png";
import SimpleOnboarding from "../../img/features/simple-onboarding.png";
import AdvancedOrderTypes from "../../img/features/advanced-order-types.png";
import AiTradingHelper from "../../img/features/ai-trading-helper.png";

const Features = () => {
  const featureArr = [
    {
      title: "Mobile Friendly",
      image: MobileFriendly,
      description: "Enjoy a responsive web interface and native applications designed for seamless mobile use.",
    },
    {
      title: "Fully Decentralized",
      image: FullyDecentralized,
      description: "Built on a native chain with a robust governance protocol.",
      link: {
        title: "Dashboard",
        href: "",
      },
    },
    {
      title: "Simple Onboarding",
      image: SimpleOnboarding,
      description:
        "Discover a trading experience that's user-friendly, cost-effective, and designed with your needs in mind.",
    },
    {
      title: "Advanced Order Types",
      image: AdvancedOrderTypes,
      description: "Trade like a pro with advanced order types, including price triggers.",
    },
    {
      title: "AI Trading Helper",
      image: AiTradingHelper,
      description:
        "Finch, our AI will make you a better trader providing live AI trading suggestions based on live price action data.",
    },
  ];
  const [activeItem, setActiveItem] = useState(featureArr[0]);
  return (
    <div className="app-feature-section">
      <div className="default-container">
        <div className="app-feature-content-root">
          <div className="app-feature-left">
            <div className="">
              <div className="app-feature-left-header">
                <h2>Features</h2>
                <p>A derivatives exchange built for you to give you best experience and have profitable trades</p>
              </div>
              <div className="app-feature-left-content">
                {featureArr.map((feature, index) => (
                  <div
                    onClick={() => setActiveItem(feature)}
                    key={index}
                    className={`app-feature-left-content-item ${feature.title !== activeItem.title && `de-active`}`}
                  >
                    <h3>{feature.title}</h3>
                    {feature.title === activeItem.title && <p>{feature.description}</p>}
                    {feature.title === activeItem.title && feature.link && (
                      <a href={feature.link.href}>
                        <div className="app-feature-left-content-item-link">
                          <p>{feature.link.title}</p>
                          <div className="">
                            <svg
                              width="19"
                              height="20"
                              viewBox="0 0 19 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_188_427)">
                                <path
                                  d="M13.9781 12.7268L13.9594 5.53537L6.76797 5.51671C6.69304 5.50656 6.61681 5.51264 6.54444 5.53451C6.47207 5.55639 6.40524 5.59356 6.34848 5.64351C6.29172 5.69346 6.24636 5.75503 6.21546 5.82403C6.18456 5.89304 6.16885 5.96788 6.16939 6.04348C6.16993 6.11909 6.18671 6.1937 6.21859 6.26225C6.25047 6.33081 6.29671 6.39172 6.35417 6.44085C6.41164 6.48999 6.47899 6.5262 6.55167 6.54704C6.62435 6.56789 6.70066 6.57287 6.77543 6.56165L12.1644 6.58405L5.02515 13.7233C4.92617 13.8222 4.87056 13.9565 4.87056 14.0965C4.87056 14.2364 4.92617 14.3707 5.02515 14.4697C5.12412 14.5686 5.25837 14.6242 5.39834 14.6242C5.53832 14.6242 5.67256 14.5686 5.77154 14.4697L12.9108 7.33044L12.9332 12.7194C12.9336 12.8594 12.9898 12.9936 13.0891 13.0922C13.1885 13.1909 13.323 13.2461 13.4631 13.2456C13.6031 13.2451 13.7373 13.189 13.8359 13.0896C13.9346 12.9902 13.9898 12.8557 13.9893 12.7156L13.9781 12.7268Z"
                                  fill="#281AF0"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_188_427">
                                  <rect width="19" height="19" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="app-feature-right">
            <img src={activeItem.image} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
