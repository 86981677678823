import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import gmxBigIcon from "img/adx_gmx.png";
import glpBigIcon from "img/adx_slp.png";

import { isHomeSite } from "lib/legacy";

import { useWeb3React } from "@web3-react/core";

import APRLabel from "../APRLabel/APRLabel";
import { HeaderLink } from "../Header/HeaderLink";
import { DEFAULT_CHAIN_ID } from "config/chains";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import "./TokenCard.css"

export default function TokenCard({ showRedirectModal, redirectPopupTimestamp }) {
  const isHome = isHomeSite();
  const { chainId } = useChainId();
  const { active } = useWeb3React();

  const changeNetwork = useCallback(
    (network) => {
      if (network === chainId) {
        return;
      }
      if (!active) {
        setTimeout(() => {
          return switchNetwork(network, active);
        }, 500);
      } else {
        return switchNetwork(network, active);
      }
    },
    [chainId, active]
  );

  const BuyLink = ({ className, to, children, network }) => {
    if (isHome && showRedirectModal) {
      return (
        <HeaderLink
          to={to}
          className={className}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          {children}
        </HeaderLink>
      );
    }

    return (
      <Link to={to} className={className} onClick={() => changeNetwork(network)}>
        {children}
      </Link>
    );
  };

  return (
    <div className="Home-token-card-options">
      <div className="Home-token-card-option">
        <div className="Home-token-card-option-icon">
          <img src={gmxBigIcon} alt="bluBigIcon" /> ADX
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-title">
            <Trans>ADX is the utility and governance token. Accrues 30% of the platform's generated fees.</Trans>
          </div>
          <div className="Home-token-card-option-apr">
            <Trans>AIGISOS APR:</Trans> <APRLabel chainId={DEFAULT_CHAIN_ID} label="gmxAprTotal" key="DEFAULT_CHAIN_ID" />{" "}
          </div>
          <div className="Home-token-card-option-action">
            <div className="buy">
              {/* <ExternalLink href="https://quickswap.exchange/" className="default-btn">
                <Trans>Buy on AIGISOS</Trans>
              </ExternalLink> */}
              {/* <BuyLink to="/buy_adx" className="default-btn" network={DEFAULT_CHAIN_ID}>
                <Trans>Buy on AIGISOS</Trans>
              </BuyLink> */}
              <button className="default-btn" network={DEFAULT_CHAIN_ID}>
                <Trans>Buy on AIGISOS</Trans>
              </button>
            </div>
            {/* <ExternalLink href="https://adx.gitbook.io/adx/usdblu" className="default-btn read-more">
              <Trans>Read more</Trans>
            </ExternalLink> */}
            <div href="https://adx.gitbook.io/adx/usdblu" className="default-btn read-more">
              <Trans>Read more</Trans>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-token-card-option">
        <div className="Home-token-card-option-icon">
          <img src={glpBigIcon} alt="blpBigIcon" /> ALP
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-title">
            <Trans>ALP is the liquidity provider token. Accrues 70% of the platform's generated fees.</Trans>
          </div>
          <div className="Home-token-card-option-apr">
            <Trans>AIGISOS APR:</Trans> <APRLabel chainId={DEFAULT_CHAIN_ID} label="glpAprTotal" key="AIGISOS" />{" "}
          </div>
          <div className="Home-token-card-option-action">
            <div className="buy">
              <BuyLink to="/buy_alp" className="default-btn" network={DEFAULT_CHAIN_ID}>
                <Trans>Buy on AIGISOS</Trans>
              </BuyLink>
            </div>
            <div
              //href="https://adx.gitbook.io/adx/usdblp"
              target="_blank"
              rel="noreferrer"
              className="default-btn read-more"
            >
              <Trans>Read more</Trans>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
