import "./styles.css";

const Team = () => {
  return (
    <div className="team">
      <div className="default-container">
        <div className="team-content">
          <div className="team-container-left">
            <p>Our Mission</p>
            <h3>
              We are on a mission to make trading fun and profitable also open up financial opportunities for everyone
              in the world.
            </h3>
          </div>
          <div className="team-container-right">
            <h3>Join our team!</h3>
            <h4>Careers</h4>
            <p>
              Ready to be part of an exciting, vision-led team? We’d love to hear from you! Reach out and join us for a
              fun and fulfilling career, send your Resume to careers@aigisos.com
            </p>

            <div className="team-container-right-links-root">
              <div className="team-container-right-link">
                <p>Social Media Manager</p>
                <div className="team-container-right-link-right">
                  <p>Remote, Global</p>
                  <div className="">
                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_188_387)">
                        <path
                          d="M13.9783 12.7268L13.9597 5.53537L6.76821 5.51671C6.69329 5.50656 6.61706 5.51264 6.54468 5.53451C6.47231 5.55639 6.40548 5.59356 6.34872 5.64351C6.29197 5.69346 6.2466 5.75503 6.2157 5.82403C6.18481 5.89304 6.1691 5.96788 6.16964 6.04348C6.17018 6.11909 6.18695 6.1937 6.21883 6.26225C6.25071 6.33081 6.29695 6.39172 6.35442 6.44085C6.41189 6.48999 6.47924 6.5262 6.55192 6.54704C6.6246 6.56789 6.7009 6.57287 6.77568 6.56165L12.1646 6.58405L5.02539 13.7233C4.92641 13.8222 4.87081 13.9565 4.87081 14.0965C4.87081 14.2364 4.92641 14.3707 5.02539 14.4697C5.12437 14.5686 5.25861 14.6242 5.39859 14.6242C5.53856 14.6242 5.6728 14.5686 5.77178 14.4697L12.911 7.33044L12.9334 12.7194C12.9339 12.8594 12.99 12.9936 13.0894 13.0922C13.1888 13.1909 13.3233 13.2461 13.4633 13.2456C13.6034 13.2451 13.7375 13.189 13.8362 13.0896C13.9349 12.9902 13.99 12.8557 13.9895 12.7156L13.9783 12.7268Z"
                          fill="#281AF0"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_188_387">
                          <rect width="19" height="19" fill="white" transform="translate(0 0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="divider"></div>
              <div className="team-container-right-link">
                <p>Social Media Manager</p>
                <div className="team-container-right-link-right">
                  <p>Remote, Global</p>
                  <div className="">
                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_188_387)">
                        <path
                          d="M13.9783 12.7268L13.9597 5.53537L6.76821 5.51671C6.69329 5.50656 6.61706 5.51264 6.54468 5.53451C6.47231 5.55639 6.40548 5.59356 6.34872 5.64351C6.29197 5.69346 6.2466 5.75503 6.2157 5.82403C6.18481 5.89304 6.1691 5.96788 6.16964 6.04348C6.17018 6.11909 6.18695 6.1937 6.21883 6.26225C6.25071 6.33081 6.29695 6.39172 6.35442 6.44085C6.41189 6.48999 6.47924 6.5262 6.55192 6.54704C6.6246 6.56789 6.7009 6.57287 6.77568 6.56165L12.1646 6.58405L5.02539 13.7233C4.92641 13.8222 4.87081 13.9565 4.87081 14.0965C4.87081 14.2364 4.92641 14.3707 5.02539 14.4697C5.12437 14.5686 5.25861 14.6242 5.39859 14.6242C5.53856 14.6242 5.6728 14.5686 5.77178 14.4697L12.911 7.33044L12.9334 12.7194C12.9339 12.8594 12.99 12.9936 13.0894 13.0922C13.1888 13.1909 13.3233 13.2461 13.4633 13.2456C13.6034 13.2451 13.7375 13.189 13.8362 13.0896C13.9349 12.9902 13.99 12.8557 13.9895 12.7156L13.9783 12.7268Z"
                          fill="#281AF0"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_188_387">
                          <rect width="19" height="19" fill="white" transform="translate(0 0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
