import { createClient } from "./utils";
import { SUBGRAPH_URLS } from "config/subgraph";
import { ARBITRUM, DEFAULT_CHAIN_ID } from "config/chains";

export const chainlinkClient = createClient(SUBGRAPH_URLS.common.chainLink);

export const nissohGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].nissohVault);

export const baseGraphClient = createClient(SUBGRAPH_URLS[DEFAULT_CHAIN_ID].stats);
export const bscReferralsGraphClient = createClient(SUBGRAPH_URLS[DEFAULT_CHAIN_ID].referrals);
export const bscGraphClientForTrades = createClient(SUBGRAPH_URLS[DEFAULT_CHAIN_ID].trades);

export function getGmxGraphClient(chainId: number) {
  if (chainId === DEFAULT_CHAIN_ID) {
    return baseGraphClient;
  }

  throw new Error(`Unsupported chain ${chainId}`);
}

export function getGmxGraphClientByNewCreate(chainId: number) {
  if (chainId === DEFAULT_CHAIN_ID) {
    return createClient(SUBGRAPH_URLS[DEFAULT_CHAIN_ID].stats);
  }

  throw new Error(`Unsupported chain ${chainId}`);
}