import "./styles.css";
import IndicatorIcon from "../../img/indicator-icon.svg";
import HeroFeaturedImage from "../../img/hero-featured-image.png";
import Brand1 from "../../img/sponsers/brand-1.png";
import Brand2 from "../../img/sponsers/brand-2.png";
import Brand3 from "../../img/sponsers/brand-3.png";
import Brand4 from "../../img/sponsers/brand-4.png";
import Brand5 from "../../img/sponsers/brand-5.png";
import Brand6 from "../../img/sponsers/brand-6.png";
import Brand7 from "../../img/sponsers/brand-7.png";
import { HeaderLink } from "components/Header/HeaderLink";
import { formatNumber } from "domain/legacy";
import { useVolumeData } from "domain/legacy";

const HeroSection = ({ showRedirectModal, redirectPopupTimestamp }) => {
  const brandsArr = [Brand1, Brand2, Brand3, Brand4, Brand5, Brand6, Brand7];
  const a = Brand1;
  const LaunchExchangeButton = () => {
    return (
      <div style={{ backgroundImage: `url(/cta-button-bg.svg)`, marginTop: "40px" }} className="">
        <HeaderLink
          className="cta-button"
          to="/perpetual_trading"
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          Launch Exchange
        </HeaderLink>
      </div>
    );
  };

  const TotalVolumeCard = () => {
    const [totalVolumeData /* , totalVolumeLoading */] = useVolumeData();
    return (
      <div className="total-volume-card">
        <img src={IndicatorIcon} />
        <div className="description">
          <p>Total Trading Volume</p>
          <h4>{formatNumber(totalVolumeData, { currency: true, compact: false })}</h4>
        </div>
      </div>
    );
  };

  const SupportedBySection = () => {
    return (
      <div className="support-by-section supported">
        <h2>Supported by the Best</h2>
        <div className="support-by-section-content">
          {brandsArr.map((brand, index) => (
            <div className="brand-image" key={index}>
              <img src={brand} alt="" />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="hero-section">
      <div className="container">
        <div className="center-horizontal">
          <div className="hero-content">
            <h1>AIDX,Your Premier Decentralized Trading Exchange</h1>
            <p>
              AIDX is the leading decentralized trading platform for advanced traders. Effortlessly trade top
              cryptocurrencies and RollApp coins with up to 50x buying power.
            </p>
            <LaunchExchangeButton />
            <TotalVolumeCard />
          </div>
          <div className="hero-featured-image supported">
            <div>
              <img src={HeroFeaturedImage} alt="" />
            </div>
          </div>
        </div>
      </div>
      <SupportedBySection />
    </div>
  );
};

export default HeroSection;
