import { ARBITRUM, AVALANCHE, BSC, AIGISOS } from "./chains";

export const SUBGRAPH_URLS = {
  [ARBITRUM]: {
    stats: "https://api.thegraph.com/subgraphs/name/gmx-io/gmx-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/gmx-io/gmx-arbitrum-referrals",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault",
  },

  [AVALANCHE]: {
    stats: "https://indexer.unilayer.app/subgraphs/name/graphprotocol/stats",
    referrals: "https://indexer.unilayer.app/subgraphs/name/graphprotocol/referrals",
    trades:"https://indexer.unilayer.app/subgraphs/name/graphprotocol/trades",
  },

  [BSC]: {
    stats: "https://api.thegraph.com/subgraphs/name/alphamatesdev/bsctest-hal-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/alphamatesdev/bsctest-hal-referrals",
    trades:"https://api.thegraph.com/subgraphs/name/alphamatesdev/bsctest-hal-trades",  
  },
  
  [AIGISOS]: {
    stats: "https://graphnode.aidx.exchange/subgraphs/name/graphprotocol/stats",
    referrals: "https://graphnode.aidx.exchange/subgraphs/name/graphprotocol/referrals",
    trades:"https://graphnode.aidx.exchange/subgraphs/name/graphprotocol/trades",  
  },
  
  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
