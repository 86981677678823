import "./Home.css";

import HeroSection from "components/HomeComponents/HeroSection";
import BenefitSection from "components/HomeComponents/BenefitSection";
import Features from "components/HomeComponents/Features";
import Team from "components/HomeComponents/Team";

// For Footer
import FooterLogo from "../../img/footer/footer-large-logo.svg";
import Github from "../../img/social/github.svg";
import Discord from "../../img/social/discord.svg";
import Medium from "../../img/social/medium.svg";
import Telegram from "../../img/social/telegram.svg";
import X from "../../img/social/x.svg";

import { isHomeSite } from "lib/legacy";
import { useEffect } from "react";


const socialLinks = [
  { link: "https://github.com/JResearchLabs/aigisos", name: "Github", icon: Github },
  { link: "https://twitter.com/aigisos", name: "Twitter", icon: X },
  { link: "https://discord.gg/sQTSYs9Mps", name: "Discord", icon: Discord },
  { link: "https://t.me/aigisos", name: "Telegram", icon: Telegram },
  { link: "https://medium.com/@aigisos", name: "Medium", icon: Medium },
];

function Footer() {
  const isHome = isHomeSite();

  return (
    <div className="app-footer">
      <div className="default-container">
        <div className="app-footer-content">
          <div className="right-section">
            <a href="https://drive.google.com/file/d/1F6g5mW5_b_SPVVJgtZ0lNGBdfx_Np9Ny/view?usp=sharing" target="_blank" rel="noopener noreferrer">
              <div className="">Litepaper</div>
            </a><br/>
            <a href="https://drive.google.com/file/d/12MvSB-fEUlu2IPPOoE61QDw0I_l-YEM8/view?usp=sharing" target="_blank" rel="noopener noreferrer">
              <div className="">Research Paper</div>
            </a><br/>
            <a href="https://drive.google.com/file/d/1ZUYITOtyMoeFYbFcPkwO2o7jrSFJ5Z-x/view?usp=sharing" target="_blank" rel="noopener noreferrer">
              <div className="">Pitch Deck</div>
            </a>
          </div>
          <div className="right-section">
            <a href="https://aidx.exchange/#/terms-and-conditions" target="_blank" rel="noopener noreferrer">
              <div className="">Terms and Conditions</div>
            </a><br/>
            <a href="https://aidx.exchange/#/referral-terms" target="_blank" rel="noopener noreferrer">
              <div className="">Referral Terms</div>
            </a><br/>
            <a href="https://aidx.exchange/#/media-kit" target="_blank" rel="noopener noreferrer">
              <div className="">Media Kit</div>
            </a>
          </div>
          <div className="right-section" style={{display: "flex", flexDirection: "column", justifyItems:"center", alignItems: "center"}}>
            <div className="social-section">
              {socialLinks.map((socialLink, index) => (
                <a href={socialLink.link} key={index} target="_blank" rel="noopener noreferrer">
                  <img src={socialLink.icon} alt={socialLink.name} />
                </a>
              ))}
            </div>
            <p>© 2023 AIDX Inc.</p>
          </div>
        </div>
      </div>

      <div className="footer-large-logo">
        <img src={FooterLogo} alt="" />
      </div>
    </div>
  );
}

export default function Home(props) {
  const { setIsHomepage } = props;
  useEffect(() => {setIsHomepage(true)}, [])
  return (
    <div className="page-layout" style={{paddingBottom: "80rem", backgroundColor: "#1470b6"}}>
      <HeroSection />
      <BenefitSection />
      <Features />
      <Team />
      <Footer />
    </div>
  );
}
