import { ReactNode } from "react";
import cx from "classnames";
import "./Button.css";

type Props = {
  imgSrc: string;
  children: ReactNode;
  onClick: () => void;
  className?: string;
  isHomepage?: boolean;
};

export default function ConnectWalletButton({ imgSrc, children, onClick, className, isHomepage }: Props) {
  let classNames = cx(`${isHomepage? "new-button" : "btn btn-primary btn-sm connect-wallet"}`, className);
  return (
    <button className={classNames} onClick={onClick}>
      {!isHomepage && imgSrc && <img className="btn-image" src={imgSrc} alt="Connect Wallet" />}
      <span className="btn-label">{children}</span>
    </button>
  );
}
