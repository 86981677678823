import React from 'react';
import "./LeverageCustomInput.css";

const LeverageCustomInput = ({ initialValue, setParentValue }) => {
  const fixValue = (value) => {
    let newValue = value;
    if (!value || value < 0.1) {
        newValue = 0.1;
    }

    if (value > 50) {
        newValue = 50.0;
    }

    return newValue.toFixed(1);
  }
  const handleStartButtonClick = () => {
    const newValue = fixValue(parseFloat(Number(initialValue)) - 0.1);
    setParentValue(newValue.toString());
  };

  const handleEndButtonClick = () => {
    const newValue = fixValue(parseFloat(Number(initialValue)) + 0.1);
    setParentValue(newValue.toString());
  };

  const handleInputChange = (event) => {
    const newValue = fixValue(parseFloat(event.target.value));
   
    setParentValue(newValue.toString());
  };

  return (
    <div className='LeverageBox '>
      <button onClick={handleStartButtonClick}>-</button>
      <input
        className='leveragebox-input'
        type="text"
        value={initialValue}
        onChange={handleInputChange}
        min={'0.1'}
        max={'50'}
      />
      <button onClick={handleEndButtonClick}>+</button>
    </div>
  );
};

export default LeverageCustomInput;
