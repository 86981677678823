import { ethers } from "ethers";
import { getContract } from "./contracts";
import { ARBITRUM, AVALANCHE, BSC, AIGISOS } from "./chains";
import { Token } from "domain/tokens";

export const TOKENS: { [chainId: number]: Token[] } = {
  [ARBITRUM]: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Wrapped Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      isWrapped: true,
      baseSymbol: "ETH",
      imageUrl: "https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295",
    },
    {
      name: "Bitcoin (WBTC)",
      symbol: "BTC",
      decimals: 8,
      address: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      address: "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/877/thumb/chainlink-new-logo.png?1547034700",
    },
    {
      name: "Uniswap",
      symbol: "UNI",
      decimals: 18,
      address: "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
      isStable: false,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12504/thumb/uniswap-uni.png?1600306604",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/thumb/Tether-logo.png?1598003707",
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734",
    },
    {
      name: "Frax",
      symbol: "FRAX",
      decimals: 18,
      address: "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F",
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/13422/small/frax_logo.png?1608476506",
    },
    {
      name: "Magic Internet Money",
      symbol: "MIM",
      decimals: 18,
      address: "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A",
      isStable: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png",
    },
  ],
  [AVALANCHE]: [
    {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Wrapped AVAX",
      symbol: "WAVAX",
      decimals: 18,
      address: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
      isWrapped: true,
      baseSymbol: "AVAX",
      imageUrl: "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818",
    },
    {
      name: "Ethereum (WETH.e)",
      symbol: "ETH",
      address: "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880",
    },
    {
      name: "Bitcoin (BTC.b)",
      symbol: "BTC",
      address: "0x152b9d0fdc40c096757f570a51e494bd4b943e50",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Bitcoin (WBTC.e)",
      symbol: "WBTC",
      address: "0x50b7545627a5162f82a992c33b87adc75187b218",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "USD Coin (USDC.e)",
      symbol: "USDC.e",
      address: "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Magic Internet Money",
      symbol: "MIM",
      address: "0x130966628846bfd36ff31a822705796e8cb8c18d",
      decimals: 18,
      isStable: true,
      isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/16786/small/mimlogopng.png",
    },
    {
      name: "Chainlink Token (LINK.e)",
      // symbol: "LINK.e",
      symbol: "LINK",
      address: "0x5947bb275c521040051d82396192181b413227a3",
      decimals: 18,
      isStable: false,
      isShortable: true,
      // isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/877/small/chainlink-new-logo.png?1547034700",
    },
    {
      name: "Aave Token (AAVE.e)",
      // symbol: "AAVE.e",
      symbol: "AAVE",
      address: "0x63a72806098bd3d9520cc43356dd78afe5d386d9",
      decimals: 18,
      isStable: false,
      isShortable: true,
      // isTempHidden: true,
      imageUrl: "https://assets.coingecko.com/coins/images/12645/small/AAVE.png?1601374110",
    },
  ],
  [BSC]: [
    {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/825/standard/bnb-icon2_2x.png?1696501970",
    },
    {
      name: "Wrapped BNB",
      symbol: "WBNB",
      decimals: 18,
      address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
      isWrapped: true,
      baseSymbol: "BNB",
      imageUrl: "https://assets.coingecko.com/coins/images/825/standard/bnb-icon2_2x.png?1696501970",
    },
    {
      name: "Binance-Pegged Cardano",
      symbol: "ADA",
      address: "0xbc215b9843a06a3a1b9b502755b226781ab81086",
      decimals: 18,
      priceDecimals: 6,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Binance-Pegged Bitcoin Cash",
      symbol: "BCH",
      address: "0xbbf97e417d2a65fffb8cb9772b0a02bd2329be01",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Binance-Pegged Bitcoin",
      symbol: "BTC",
      address: "0xdb6612e49f901ed9231995a04917ac292bac59a7",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "PancakeSwap",
      symbol: "CAKE",
      address: "0xd3f07660e4cb26cad001834bb3eb5df688a63733",
      decimals: 18,
      priceDecimals: 4,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Binance-Pegged Dogecoin",
      symbol: "DOGE",
      address: "0x39349d7dc0b141d190d07294623a995fabd4a860",
      decimals: 18,
      priceDecimals: 6,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Binance-Pegged Polkadot",
      symbol: "DOT",
      address: "0xba13560e2165ae2b35b8fcd45f9649a175c47af5",
      decimals: 18,
      priceDecimals: 4,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Binance-Pegged Ethereum",
      symbol: "ETH",
      address: "0x7262ff9c51db7b8d727458be34476b564c6c542c",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
    },
    {
      name: "Binance-Pegged Filecoin",
      symbol: "FIL",
      address: "0xb2bf204275be6dc59bec8722acf5edb91a27241c",
      decimals: 18,
      priceDecimals: 4,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
    },
    {
      name: "Binance-Pegged Injective Protocal",
      symbol: "INJ",
      address: "0x0505a13a235c2df4a6a5bc74b029c444fb16a752",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
    },
    {
      name: "Binance-Pegged Chainlink",
      symbol: "LINK",
      address: "0x873a8532b1fb42a46b37ad128d399d082bc81a59",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
    },
    {
      name: "Binance-Pegged Litecoin",
      symbol: "LTC",
      address: "0xdc0d609eb5cb5085031ee02b0b0df475101e7cde",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
    },
    {
      name: "Binance-Pegged Polygon",
      symbol: "MATIC",
      address: "0xd6bbdc188234f79cb1f4b6a2f4dd3b3e23ea0958",
      decimals: 18,
      priceDecimals: 6,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
    },
    {
      name: "Binance-Pegged Tron",
      symbol: "TRX",
      address: "0x0a3d8d1fffe9dfd83ca182e3fccd5fcbe23bc50f",
      decimals: 18,
      priceDecimals: 6,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
    },
    {
      name: "Binance-Pegged XRP",
      symbol: "XRP",
      address: "0xb2ec5ca1495a5a8ec55c0b515df981831ee540aa",
      decimals: 18,
      priceDecimals: 6,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
    },
    {
      name: "Venus",
      symbol: "XVS",
      address: "0x8c563d4653784d11d31e56821873f32c464a2009",
      decimals: 18,
      priceDecimals: 4,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
    },
    {
      name: "Binance-Pegged USDC",
      symbol: "USDC",
      address: "0x06c4fd389c968dc6467d65d65fd578d07e72a24c",
      decimals: 18,
      isStable: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "Binance-Pegged Tether",
      symbol: "USDT",
      address: "0x1d0351b4b1ba4790e7ebfb8066e64baec36b924a",
      decimals: 18,
      isStable: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661",
    },
    {
      name: "Binance-Pegged DAI",
      symbol: "DAI",
      address: "0x990630914aca0f9ec3ca64ac8fe1b09dcf600494",
      decimals: 18,
      isStable: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661",
    },
  ],
  [AIGISOS]: [
    {
      name: "Aigisos-Pegged Bitcoin",
      symbol: "BTC",
      address: "0xcada604d963048b1b156420b1601894fa85837da",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/26115/thumb/btcb.png?1655921693",
    },
    {
      name: "Aigisos-Pegged Ethereum",
      symbol: "ETH",
      address: "0xb75e631c14bd60e77d9605754bda1a2b1e915af0",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
    },
    {
      name: "Aigisos-Pegged USDT",
      symbol: "USDT",
      address: "0xbc6b11e8f600bcc35493fe84045c5a5da7a355a6",
      decimals: 18,
      isStable: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389",
    },
    {
      name: "AIG",
      symbol: "AIG",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.coingecko.com/coins/images/825/standard/bnb-icon2_2x.png?1696501970",
    },
    {
      name: "Wrapped AIG",
      symbol: "WAIG",
      decimals: 18,
      address: "0x82dde8fb6b920a9d23490bdab7994ad58a3c439a",
      isWrapped: true,
      baseSymbol: "AIG",
      imageUrl: "https://assets.coingecko.com/coins/images/825/standard/bnb-icon2_2x.png?1696501970",
    },
  ],
};

export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {
  [ARBITRUM]: [
    {
      name: "GMX",
      symbol: "GMX",
      address: getContract(ARBITRUM, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed HAL",
      symbol: "esGMX",
      address: getContract(ARBITRUM, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "GMX LP",
      symbol: "GLP",
      address: getContract(ARBITRUM, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/Unilayer-Dex/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [AVALANCHE]: [
    {
      name: "HAL",
      symbol: "HAL",
      address: getContract(AVALANCHE, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    {
      name: "Escrowed HAL",
      symbol: "esLAYER",
      address: getContract(AVALANCHE, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "HAL LP",
      symbol: "HLP",
      address: getContract(ARBITRUM, "GLP"),
      decimals: 18,
      imageUrl: "https://github.com/Unilayer-Dex/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  ],
  [BSC]: [
    {
      name: "HAL",
      symbol: "HAL",
      address: getContract(BSC, "GMX"),
      decimals: 18,
      imageUrl: "",
    },
    {
      name: "Escrowed HAL",
      symbol: "esLAYER",
      address: getContract(BSC, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "HAL LP",
      symbol: "HLP",
      address: getContract(BSC, "GLP"),
      decimals: 18,
      imageUrl: "",
    },
  ],
  [AIGISOS]: [
    {
      name: "ADX",
      symbol: "ADX",
      address: getContract(AIGISOS, "GMX"),
      decimals: 18,
      imageUrl: "",
    },
    {
      name: "Escrowed ADX",
      symbol: "esADX",
      address: getContract(AIGISOS, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "ADX LP",
      symbol: "ALP",
      address: getContract(AIGISOS, "GLP"),
      decimals: 18,
      imageUrl: "",
    },
  ],
};

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {
  [ARBITRUM]: {
    // arbitrum
    GMX: {
      name: "GMX",
      symbol: "GMX",
      decimals: 18,
      address: getContract(ARBITRUM, "GMX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    GLP: {
      name: "GMX LP",
      symbol: "GLP",
      decimals: 18,
      address: getContract(ARBITRUM, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "https://github.com/Unilayer-Dex/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [AVALANCHE]: {
    // avalanche
    GMX: {
      name: "HAL",
      symbol: "HAL",
      decimals: 18,
      address: getContract(AVALANCHE, "GMX"),
      imageUrl: "https://assets.coingecko.com/coins/images/18323/small/arbit.png?1631532468",
    },
    GLP: {
      name: "HAL LP",
      symbol: "HLP",
      decimals: 18,
      address: getContract(AVALANCHE, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "https://github.com/Unilayer-Dex/gmx-assets/blob/main/GMX-Assets/PNG/GLP_LOGO%20ONLY.png?raw=true",
    },
  },
  [BSC]: {
    // BSC
    GMX: {
      name: "Unilayer",
      symbol: "HAL",
      decimals: 18,
      address: getContract(BSC, "GMX"),
      imageUrl: "",
    },
    GLP: {
      name: "HAL LP",
      symbol: "HLP",
      decimals: 18,
      address: getContract(BSC, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "",
    },
  },
  [AIGISOS]: {
    // AIGISOS
    GMX: {
      name: "ADX",
      symbol: "ADX",
      decimals: 18,
      address: getContract(AIGISOS, "GMX"),
      imageUrl: "",
    },
    GLP: {
      name: "AIGISOS LP",
      symbol: "ALP",
      decimals: 18,
      address: getContract(AIGISOS, "StakedGlpTracker"), // address of fsGLP token because user only holds fsGLP
      imageUrl: "",
    },
  },
};

export const ICONLINKS = {
  [ARBITRUM]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      arbitrum: "https://arbiscan.io/address/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
    },
    GLP: {
      arbitrum: "https://arbiscan.io/token/0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/ethereum",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      arbitrum: "https://arbiscan.io/address/0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      arbitrum: "https://arbiscan.io/address/0xf97f4df75117a78c1a5a0dbb814af92458539fb4",
    },
    UNI: {
      coingecko: "https://www.coingecko.com/en/coins/uniswap",
      arbitrum: "https://arbiscan.io/address/0xfa7f8980b0f1e64a2062791cc3b0871572f1f7f0",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      arbitrum: "https://arbiscan.io/address/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      arbitrum: "https://arbiscan.io/address/0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      arbitrum: "https://arbiscan.io/address/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      arbitrum: "https://arbiscan.io/address/0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
    },
    FRAX: {
      coingecko: "https://www.coingecko.com/en/coins/frax",
      arbitrum: "https://arbiscan.io/address/0x17fc002b466eec40dae837fc4be5c67993ddbd6f",
    },
  },
  [AVALANCHE]: {
    GMX: {
      coingecko: "https://www.coingecko.com/en/coins/gmx",
      avalanche: "https://snowtrace.io/address/0xDc2C540Fd99eb5dC424095C8C6A63f7c805D46e9",
    },
    GLP: {
      avalanche: "https://snowtrace.io/address/0x0E2b5428CE48c87a33Ad55A14d3b79BC7300C241",
    },
    AVAX: {
      coingecko: "https://www.coingecko.com/en/coins/avalanche",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      avalanche: "https://snowtrace.io/address/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
    },
    WBTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      avalanche: "https://snowtrace.io/address/0x50b7545627a5162f82a992c33b87adc75187b218",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/bitcoin-avalanche-bridged-btc-b",
      avalanche: "https://snowtrace.io/address/0x152b9d0FdC40C096757F570A51E494bd4b943E50",
    },
    MIM: {
      coingecko: "https://www.coingecko.com/en/coins/magic-internet-money",
      avalanche: "https://snowtrace.io/address/0x130966628846bfd36ff31a822705796e8cb8c18d",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      avalanche: "https://snowtrace.io/address/0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
    },
    "USDC.e": {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin-avalanche-bridged-usdc-e",
      avalanche: "https://snowtrace.io/address/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      avalanche: "https://snowtrace.io/address/0x5947BB275c521040051D82396192181b413227A3",
    },
    AAVE: {
      coingecko: "https://www.coingecko.com/en/coins/aave",
      avalanche: "https://snowtrace.io/address/0x63a72806098Bd3D9520cC43356dD78afe5D386D9",
    },
  },
  [BSC]: {
    GMX: {
      bsc: "https://testnet.bscscan.com/address/0x09dCFd81F8618076C7Dd3644E44655ed6e9e78ED",
    },
    GLP: {
      bsc: "https://testnet.bscscan.com/address/0xcF850A2266bA293bbdCa5e63968B762743804FdB",
    },
    BNB: {
      coingecko: "https://www.coingecko.com/en/coins/bnb",
    },
    ADA: {
      coingecko: "https://www.coingecko.com/en/coins/cardano",
      bsc: "https://testnet.bscscan.com/address/0xBc215B9843a06a3A1b9B502755b226781aB81086",
    },
    BCH: {
      coingecko: "https://www.coingecko.com/en/coins/bitcoin-cash",
      bsc: "https://testnet.bscscan.com/address/0xbbf97E417D2a65FFfB8cB9772b0a02bD2329be01",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/bitcoin",
      bsc: "https://testnet.bscscan.com/address/0xDb6612e49f901Ed9231995A04917Ac292BaC59A7",
    },
    CAKE: {
      coingecko: "https://www.coingecko.com/en/coins/pancakeswap",
      bsc: "https://testnet.bscscan.com/address/0xd3F07660E4cB26cAD001834Bb3eB5df688A63733",
    },
    DOGE: {
      coingecko: "https://www.coingecko.com/en/coins/dogecoin",
      bsc: "https://testnet.bscscan.com/address/0x39349D7dc0B141d190d07294623A995FaBD4A860",
    },
    DOT: {
      coingecko: "https://www.coingecko.com/en/coins/dogecoin",
      bsc: "https://testnet.bscscan.com/address/0x39349D7dc0B141d190d07294623A995FaBD4A860",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      bsc: "https://testnet.bscscan.com/address/0x7262FF9C51DB7b8D727458BE34476b564C6C542c",
    },
    FIL: {
      coingecko: "https://www.coingecko.com/en/coins/filecoin",
      bsc: "https://testnet.bscscan.com/address/0xb2bF204275Be6Dc59bec8722Acf5edB91a27241c",
    },
    INJ: {
      coingecko: "https://www.coingecko.com/en/coins/injective",
      bsc: "https://testnet.bscscan.com/address/0x0505a13a235c2Df4A6a5Bc74b029c444fb16A752",
    },
    LINK: {
      coingecko: "https://www.coingecko.com/en/coins/chainlink",
      bsc: "https://testnet.bscscan.com/address/0x873A8532b1fb42A46B37Ad128d399d082bc81A59",
    },
    LTC: {
      coingecko: "https://www.coingecko.com/en/coins/litecoin",
      bsc: "https://testnet.bscscan.com/address/0xdc0d609Eb5cB5085031Ee02B0b0df475101e7cDE",
    },
    MATIC: {
      coingecko: "https://www.coingecko.com/en/coins/polygon",
      bsc: "https://testnet.bscscan.com/address/0xd6bBDC188234F79CB1f4B6a2F4DD3b3e23EA0958",
    },
    TRX: {
      coingecko: "https://www.coingecko.com/en/coins/tron",
      bsc: "https://testnet.bscscan.com/address/0x0a3d8d1ffFE9dFD83Ca182E3FCCD5fcBe23bc50F",
    },
    XRP: {
      coingecko: "https://www.coingecko.com/en/coins/xrp",
      bsc: "https://testnet.bscscan.com/address/0xB2eC5CA1495A5A8eC55C0b515dF981831eE540AA",
    },
    XVS: {
      coingecko: "https://www.coingecko.com/en/coins/venus",
      bsc: "https://testnet.bscscan.com/address/0x8c563d4653784D11d31E56821873F32C464A2009",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      bsc: "https://testnet.bscscan.com/address/0x06C4Fd389c968Dc6467D65D65fd578D07e72a24c",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      bsc: "https://testnet.bscscan.com/address/0x1D0351B4b1Ba4790e7eBfB8066e64baEc36b924a",
    },
    DAI: {
      coingecko: "https://www.coingecko.com/en/coins/dai",
      bsc: "https://testnet.bscscan.com/address/0x990630914aCA0f9Ec3cA64Ac8fE1B09DCf600494",
    },
  },
  [AIGISOS]: {
    GMX: {
      scanexplorer: "https://aigisos.fyi/address/0xA05d0C937ba330EB3EC8b8E2eA5FbfBC9Aa4997C",
    },
    GLP: {
      scanexplorer: "https://aigisos.fyi/address/0x00a056Ba9E4fd1Ea02C1D8beaCE048F805851fD5",
    },
    AIG: {
      scanexplorer: "https://aigisos.fyi/address/0x82Dde8Fb6B920A9d23490bdaB7994aD58A3c439A",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/bitcoin",
      scanexplorer: "https://aigisos.fyi/address/0xcADA604d963048b1b156420B1601894Fa85837dA",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      scanexplorer: "https://aigisos.fyi/address/0xb75e631C14bD60E77D9605754bDa1A2b1E915AF0",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
      scanexplorer: "https://aigisos.fyi/address/0xBc6b11e8f600bCc35493Fe84045C5a5Da7a355a6",
    },
  },
};

export const GLP_POOL_COLORS = {
  ADA: "#006246",
  BCH: "#6000a6",
  BNB: "#AAAAFF",
  BTC: "#F7931A",
  CAKE: "#AA22FF",
  DOGE: "#3366EE",
  DOT: "#CD88B6",
  ETH: "#6062a6",
  FIL: "#0075CA",
  INJ: "#2727CA",
  LINK: "#3256D6",
  LTC: "#2695F8",
  MATIC: "#AAAAFF",
  TRX: "#E91600",
  XRP: "#E949E5",
  XVS: "#9695F8",
  WBTC: "#F7931A",
  USDC: "#2775CA",
  "USDC.e": "#2A5ADA",
  USDT: "#67B18A",
  DAI: "#FAC044",

  FRAX: "#000",
  UNI: "#E9167C",
  AVAX: "#E84142",
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};

const CHAIN_IDS = [AIGISOS];

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string) {
  address = address.toLowerCase();

  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDG");
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}

export function isChartAvailabeForToken(chainId: number, tokenSymbol: string) {
  let token;

  try {
    token = getTokenBySymbol(chainId, tokenSymbol);
  } catch (e) {
    return false;
  }

  if (token.isChartDisabled || token.isPlatformToken) return false;

  return true;
}

export function getPriceDecimals(chainId: number, tokenSymbol?: string) {
  if (!tokenSymbol) return 2;

  try {
    const token = getTokenBySymbol(chainId, tokenSymbol);
    return token.priceDecimals ?? 2;
  } catch (e) {
    return 2;
  }
}