import { ARBITRUM, AVALANCHE, BSC, AIGISOS } from "./chains";

export const XGMT_EXCLUDED_ACCOUNTS = [
];

const CONTRACTS = {
  [ARBITRUM]: {
    // arbitrum mainnet
    Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
    Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
    VaultReader: "0xfebB9f4CAC4cD523598fE1C5771181440143F24A",
    Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
    GlpManager: "0x321F653eED006AD1C29D174e17d96351BDe22649",
    RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
    RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    GLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    GMX: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    ES_GMX: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
    BN_GMX: "0x35247165119B69A40edD5304969560D0ef486921",
    USDG: "0x45096e7aA921f27590f8F19e457794EB09678141",
    ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
    StakedGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    BonusGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    FeeGmxTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    StakedGlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    FeeGlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

    StakedGmxDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    StakedGlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

    GmxVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
    GlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

    OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
    OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
    OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

    PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
    PositionManager: "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C",

    UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
    ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",
  },
  // [AVALANCHE]: {
  //   // avalanche
  //   Vault: "0x9ab2De34A33fB459b538c43f251eB825645e8595",
  //   Router: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8",
  //   VaultReader: "0x66eC8fc33A26feAEAe156afA3Cb46923651F6f0D",
  //   Reader: "0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd",
  //   GlpManager: "0xe1ae4d4b06A5Fe1fc288f6B4CD72f9F8323B107F",
  //   RewardRouter: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327",
  //   RewardReader: "0x04Fc11Bd28763872d143637a7c768bD96E44c1b6",
  //   NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
  //   GLP: "0x01234181085565ed162a948b6a5e88758CD7c7b8",
  //   GMX: "0x62edc0692BD897D2295872a9FFCac5425011c661",
  //   ES_GMX: "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
  //   BN_GMX: "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
  //   USDG: "0xc0253c3cC6aa5Ab407b5795a04c28fB063273894",
  //   ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

  //   StakedGmxTracker: "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
  //   BonusGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
  //   FeeGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
  //   StakedGlpTracker: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
  //   FeeGlpTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",

  //   StakedGmxDistributor: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
  //   StakedGlpDistributor: "0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720",

  //   GmxVester: "0x472361d3cA5F49c8E633FB50385BfaD1e018b445",
  //   GlpVester: "0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A",

  //   OrderBook: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
  //   OrderExecutor: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
  //   OrderBookReader: "0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B",

  //   PositionRouter: "0xffF6D276Bc37c61A23f06410Dce4A400f66420f8",
  //   PositionManager: "0xA21B83E579f4315951bA658654c371520BDcB866",

  //   TraderJoeGmxAvaxPool: "0x0c91a070f862666bbcce281346be45766d874d98",
  //   ReferralStorage: "0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8",
  //   ReferralReader: "0x505Ce16D3017be7D76a7C2631C0590E71A975083",
  // },
  [AVALANCHE]: {
    // avalanche
    Vault: "0x2a5bae3eaec36d147dFE859b1a68F1A5eCCB0dd5",//replaced 0x9e34FDE4Bf55061a1805C11654C21acCd34d511E
    Router: "0x8F16166be2B3a778AbBA0EbaaeC8f6bd8d4aD705",//replaced 0x6A154CE91003Cf4b8787280fd7C96D9BFb3f88C3
    VaultReader: "0xD9193457a09fA7a16e3B628129F43C1D0Ff6c6eD",//replaced 0x9DFEbCA2fa5318093016892dC772CC7132dDcE43
    Reader: "0x5921A3E1545A374C20Ab1f7413d78B3815b667dA",//replaced 0x8D88Aa3F648e751f989E528a32577Ee434B8e47F
    GlpManager: "0x9d530DdC986bd20beC3E70dC5382dF2e0a1bE7a6",//replaced 0x3a417b2949d59B129e5C6c0A52114335C780B9AE
    RewardRouter: "0x6E53C1cDA69858B02779FA2656F8C19a933CB9e0",//replaced 0x0AB63435EbA15CCedb44d86Cf3e2f1d8DBeB9e08
    RewardReader: "0xC1aEad06886edaBE97e068800EF2877568C2fFAf",//replaced 0x4Ee70052E9c64602Ab96a2A61850E72A051bbd89
    NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    GLP: "0x0E2b5428CE48c87a33Ad55A14d3b79BC7300C241",//replaced 0xA63FbC76dDaf2F800B3699a4a46C5f260E04050C
    GMX: "0xDc2C540Fd99eb5dC424095C8C6A63f7c805D46e9", //replaced 0x39E1Da9a034Fd5ADba01C7F6cFA8B5dE16dD908c
    ES_GMX: "0xafb5b77201aF3afbeE2b25c76b2DcE618a65d3bf",//replaced 0x6CdEf99C74CcF3FA65211fF547Be5dDB4A73770C
    BN_GMX: "0xE7807Af3c341CeA2f02a414e444aAD85B4D3d6df",//replaced 0x6e29e6db1Ea778fCC17BA575C8fB22A4dfeAE08f
    USDG: "0xCFc3be74C254Ca3F6B029780F804CE1289793756", //replaced 0x775003195F41AFa4f75F751c35F06B3dF76F8e04
    ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

    StakedGmxTracker: "0x81d201D05162B2E5f6B990E5950841651066438B",//replaced 0x48d7f4529f6149c5EB96AeF38534b90AD7562b4d
    BonusGmxTracker: "0xBb694c9D5454CbFbA8f2befde84204c7FD4D3bc5",//replaced 0xC5fcC14560781C4c9FD55d7466d781539177A3a4
    FeeGmxTracker: "0x71fbf7C63a196CE008aAbd2f9BB68619bFb3A837",//replaced 0xb31018C29500a565e511a0800dA87e1457CdE9b1
    StakedGlpTracker: "0x508a28d2d6B866cA6DE03044D32FE05E8672d55E",//replaced 0x8b498C45465f4a7e9CEc0D12Aa6a695A6b563A34
    FeeGlpTracker: "0xacE33f27a278f110CCcAedaD77481DE1598793b1",//replaced 0x82b84dc1A46D43747496E62BBEE2c70Ef8EE4EAD

    StakedGmxDistributor: "0xa7256d891F294866Bc060c764C1B3c15b66C798B",//replaced 0x4e11F35A9c226be709078787cC44375FD7c22424
    StakedGlpDistributor: "0x9a8fe3cCe5ca1470D213aFcb0C04ba46525c631F",//replaced 0x7ed80C511359db0E34e2FbF14aB12Ee9AfAB0Baa

    GmxVester: "0x57Ff6E61E703293742F1a4aF22262582E03429cb",//replaced 0x957C9a17fc5A5Fda190D1Bc4Fc1AF2B6282C2743
    GlpVester: "0x9024C2ac08c250fCACD1c6D60D7321c759af2cd5",//replaced 0xcf920DC4df556267A82783c4647cbe9Ce55cB1A2

    OrderBook: "0x2cd0640F9bA7C0a0306C92a674be4f89eF89cE80",//replaced 0xA76fB4882bcb66fBe68948B71eBe7f3B80e329Ea
    OrderExecutor: "0xA76fB4882bcb66fBe68948B71eBe7f3B80e329Ea",//replaced
    OrderBookReader: "0xD44d5D7605c388bE8A9eeEBc37Ba24188CD1e634",//replaced 0x1d47908DC429b00611bd11AF78A7D39E7Dc6bCa2

    PositionRouter: "0xA8A8ef28cE7cDC25319f0E13fD8245F26caf8849",//replaced 0xD3D403595ccBB99537Af13099aC89A102AAd70D1
    PositionManager: "0xda18A5372fF256314fd3654FA074B4d949aBE823",//replaced 0xD5326A526f78667375D9D5dA7C739e261Df52fe6

    TraderJoeGmxAvaxPool: "0x153DA2C6B4Ed40B4C67a8c95EE45E93B5c85786F",//replaced
    ReferralStorage: "0xF51Def0A8A991E2Aa227643271d290c2DAa8cFCe",//replaced 0xa4Ac7025c01e8fa2bfB7f274913e76b5d4d719de
    ReferralReader: "0x69A0542B67622056B401159360692b1011B23B87",//replaced 0xDe0D6DbCCB35b92e0952C51ddDD81C7A86C5E480
  },
  [BSC]: {
    // base
    Vault: "0x970ff5cd3FEF9C8BC3489aB4F984db8C83335064",
    Router: "0x9111E0Bae39488F1da036df0fEE89b91f41bA520",
    VaultReader: "0x0Ce4904493529596FaaB112003F3FcFf7FBAefD6",
    Reader: "0xd2483cd01f62d5129Db499B49C3cc55B660bb651",
    GlpManager: "0xCA21E105773EC5f1d0f7e61EDEE835a873EaE81B",
    RewardRouter: "0xc9Aef4869d77361c6203AD768DDF5515D71A1D89",
    RewardReader: "0xF6Ed614c9108373062061CA274271b9fD5d84846",
    NATIVE_TOKEN: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
    GLP: "0xcF850A2266bA293bbdCa5e63968B762743804FdB",
    GMX: "0x09dCFd81F8618076C7Dd3644E44655ed6e9e78ED",
    ES_GMX: "0x98083Ee202085B1cbe1b0932F6bDcb5027be9E65", 
    BN_GMX: "0xe83097BC91549A5421c256938A68AE8aA5EF6b08", 
    USDG: "0x1f2C441FeFEC182240938Fab741386B8c08F8117",
    ES_GMX_IOU: "0xbD01750c7FC2C6b193b42b741BFA1801bF694f83", // placeholder address

    StakedGmxTracker: "0x2194daE8BacAc9815941cE50f977d36456836fA8",
    BonusGmxTracker: "0x3e8846F9372a5D88F5E8a8E49E43A8242c25380F", 
    FeeGmxTracker: "0x31B8B8aED10D2D18F39eAE884C2746b9C64f1Cd1",
    StakedGlpTracker: "0x918897BeC3B67dBFE16E89A4B0C1A7Dc7bf991ef", 
    FeeGlpTracker: "0x5a2Abb97AD20e4780D691514461C49178B225dbb",

    StakedGmxDistributor: "0x27bCC905f5019226D2E7B75a42f2a3448D412BA2",
    StakedGlpDistributor: "0xAcB3f0044437Ef5A8245D1EAdfcf8f5A8d226Fec",

    GmxVester: "0x47eae9bB841E0378DE82EA03fBF4255865C47b80",
    GlpVester: "0x30eB648709E05B0399c365F54823423DDc9211DC",

    OrderBook: "0x47E89ba29396b6772b6C1c954D079fb0FF879e7D",
    OrderExecutor: "0xA76fB4882bcb66fBe68948B71eBe7f3B80e329Ea",//replaced //unused
    OrderBookReader: "0x7d00b5Fde9f4cf6d197900a164e36Dfd0Def1863",

    // PositionRouter: "0x19eE6413eebdCF52CF752708783A7bb3309106d0",
    PositionRouter: "0xD76688D1b0A22861bD6471950C2B000E4A8c3FF5",
    PositionManager: "0x976aB9ACA8E295DaF6278Fab9DfF6250Ec0aD6E4",

    TraderJoeGmxAvaxPool: "0xcB4db89D853B70BD547C27614313b9Ad56Ec353c", //"0xCce7123BB2dC256E95c537eb1D09711b7815E228",//replaced
    ReferralStorage: "0x838607C1e565b05B6f6FEfF9CC15227230a25e8F",
    ReferralReader: "0x1BBB204fB1dED62CdFBB682498a2A6aA602cb217",
  },
  [AIGISOS]: {
    // aigisos testnet
    Vault: "0xf311ef83c60755f11c05Da094aDad637269Acc01", // Vault
    Router: "0x50ab81a70f02a09Ff3705feDd10575424F34172C", // Router
    VaultReader: "0x5d90703Afc95Ab1682Ba13eaAB29Cf7a2CC7f152", // VaultReader
    Reader: "0x062EB4f128811C291B4f9b7d02a7A8bEC9920Cb6", // Reader
    GlpManager: "0x4C4D60e45cA6A25FE59fEB3254AC5c59968E47A5", // AlpManager
    RewardRouter: "0x597da3E907F8fcb44C42139D5a9729eA15af805D", // RewardRouterV2
    RewardReader: "0x4e14E79586107b81431Ea94F172460c53CFAdb5C", // RewardReader
    NATIVE_TOKEN: "0x82Dde8Fb6B920A9d23490bdaB7994aD58A3c439A",
    GLP: "0xC0CD40F113B875D8500fce123140cA9124D33e97", // ALP
    GMX: "0x7A4ec39B661f0659F06A8AD143D2fEDbd474240C", // ADX
    ES_GMX: "0x7fFa3Bc6638F4b90D007FDE64DE11EF66843971e", // EsADX
    BN_GMX: "0x076cD6C42BdDEdF55B520257244AaFd800ba1899", // BonusADX
    USDG: "0xe1708A3f15e2d56097F055E978c26165f2cFc5DE", // USDG
    ES_GMX_IOU: "", // placeholder address

    StakedGmxTracker: "0x58806776A454ba74690f2e7D4E61Cc355Df52E4a", // RewardTrackerStakedADX
    BonusGmxTracker: "0x6951eE86042F244cD2646A544C8a5Bce1209E690", // RewardTrackerStakedBonusADX
    FeeGmxTracker: "0x49752B8286Abef71d81564487555A94ed7707075", // RewardTrackerStakedBonusFeeADX
    StakedGlpTracker: "0x8f9dE06FC8b5c52ad6221BFF155E2e208b93A99a", // RewardTrackerFeeStakedALP
    FeeGlpTracker: "0x2D1ec9De764554f57AfbcF47dF73664b4834E6f2", // RewardTrackerFeeALP

    StakedGmxDistributor: "0x391522ab72cdb384945F801c6f642f26027a3eD1", // RewardDistributorStakedADX
    StakedGlpDistributor: "0x3BB7c00B93458329fd3CA7470f2B099EF539f6e0", // RewardDistributorFeeStakedALP

    GmxVester: "0xd508ef8Fd9387dd0017Ff0CEbDAeD79aDa7B9330", // VestedADX
    GlpVester: "0x5C6859c628786113185935e343Fe6334EB3D271B", // VestedALP

    OrderBook: "0xde46b30e6A31C882A517432864476880952a7079", // OrderBook
    OrderBookReader: "0x9657af4Af08bCFD7b225Db8326E6A8F658Ab1261", // OrderBookReader

    PositionRouter: "0xA6B1D71049b8821b0a73B5A8Bd02931d5B4D526D", // PositionRouter
    PositionManager: "0xc2291997c53A827F24780DE62Ff60425D9369138", // PositionManager

    TraderJoeGmxAvaxPool: "0x41Ac01E11CE5d49e384D646b264C7D9755b78585",//replaced
    ReferralStorage: "0x3293500535039002391dA8A49635a0659d2Bf284", // ReferralStorage
    ReferralReader: "0x9E77454a1fC28A46D254dF69A61B1A64470783D5", // ReferralReader
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
