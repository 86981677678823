import React, { useEffect } from "react";

import { DEFAULT_CHAIN_ID, getConstant } from "config/chains";

import StakeV1 from "./StakeV1";
import StakeV2 from "./StakeV2";

export default function Stake(props) {
  const { setIsHomepage } = props;
  
  useEffect(() => { setIsHomepage(false) }, []);

  const chainId = DEFAULT_CHAIN_ID;
  const isV2 = getConstant(chainId, "v2");
  return isV2 ? <StakeV2 {...props} /> : <StakeV1 />;
}
